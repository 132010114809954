import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { history } from '../store'
import AppBaqueiraHeader from '../containers/AppBaqueiraHeader'
import { withNamespaces } from "react-i18next";
import StepBarBaqueira from '../components/StepBarBaqueira'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

class PhoneBaqueiraView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      phoneNumber: '',
      mail: '',
      zona: '1',
      accesible: 'NO',
      confirmReservation: false,
      newsletterAcceptance: false,
      qrsale: null,
      booking: null,
      lockerSelected: null,
      lockerPrice: null,
      descriptionZone: null,
      selectedAccessibility: null,
      zoneSelected: null,
      selectedLocker: null,
      lockerAssigned: null,
      lockerPrice: null,
      paymentId: null,
      qrToken: null,
      lockerPin: null,
      lockerMatricula: null,
      lockerCustomer: null,
      lockerInvoice: null,
      qrType: "pay",
      qrMode: 0,
      customerName: '',
      language: null,
      startDate: null,
      endDate: null,
    }

    this.toggle = this.toggle.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handleMailChange = this.handleMailChange.bind(this);
    this.handleCustomerNameChange = this.handleCustomerNameChange.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  handlePhoneNumberChange(value) {
    const phoneNumber = value;
    this.setState({ phoneNumber });
  }

  handleCustomerNameChange(event) {
    const customerName = event.target.value;
    this.setState({ customerName });
  }

  handleMailChange(event) {
    const mail = event.target.value;
    const isValidEmail = this.validateEmail(mail);
    this.setState({ mail, isValidEmail });
  }

  validateEmail(email) {
    // A simple email validation pattern using regular expression
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  goTo(e) {
    const {
      zoneSelected,
      descriptionZone,
      selectedLocker,
      lockerPrice,
      lockerAssigned,
      prerentCode,
      prerentTime,
      totalDays,
      startDate,
      endDate,
      qrType,
      qr,
    } = this.props.location.state;

    if (!this.isContinueDisabled()) {
      this.props.push('/PaymentBaqueiraView', {
        // PASSED VARS
        zoneSelected: zoneSelected,
        descriptionZone: descriptionZone,
        selectedLocker: selectedLocker,
        lockerPrice: lockerPrice,
        lockerAssigned: lockerAssigned,
        prerentCode: prerentCode,
        prerentTime: prerentTime,
        totalDays: totalDays,
        startDate: startDate,
        endDate: endDate,
        qrType: qrType,
        qr: qr,
        // PHONE VARS
        customerName: this.state.customerName,
        phoneNumber: this.state.phoneNumber,
        mail: this.state.mail,
        newsletterAcceptance: this.state.newsletterAcceptance,
        confirmReservation: this.state.confirmReservation,
      });
    }
  }

  componentDidMount() {

  }

  isContinueDisabled() {

    const disabled = ((this.state.phoneNumber.length < 6) || !this.state.isValidEmail || !this.state.confirmReservation || this.state.customerName.length == 0);

    return disabled;
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <div>
        <AppBaqueiraHeader />
        <div className="lockerContainer">
          <br></br>
          <StepBarBaqueira step={3} />
          <br></br>
          <h3 className="lockTitle">
            <span className="text-vw-dark-baqueira">{t('FACILITANOS TUS DATOS DE RESPALDO')}</span>
          </h3>
          <div className="lockTitle">
            <span className="text-vw-dark-baqueira">{t('Tu teléfono se vinculará a la taquilla reservada para poder recuperar el Pin de esta en caso de que lo pierdas. Al email te llegará la confirmación de tu reserva.')}</span>
          </div>
          <div>
            <br></br>
            <h3 className="lockTitle">
              <span className="text-vw-dark-baqueira">{t('NOMBRE COMPLETO*')}</span>
            </h3>
            <input className="phoneInput"
              name="name"
              type="text"
              value={this.state.customerName}
              onChange={this.handleCustomerNameChange}
            />
            <br></br>
            <h3 className="lockTitle">
              <span className="text-vw-dark-baqueira">{t('TELÉFONO*')}</span>
            </h3>
            <PhoneInput
              country="es" // default country code
              value={this.state.phoneNumber}
              onChange={this.handlePhoneNumberChange}
              inputClass="phoneInput" // style to match form
              pattern="[0-9]*"
            />
            <br></br>
            <h3 className="lockTitle">
              <span className="text-vw-dark-baqueira">{t('EMAIL*')}</span>
            </h3>
            <input className="phoneInput"
              name="lockerEmail"
              type="text"
              value={this.state.mail}
              onChange={this.handleMailChange}
              autoCapitalize='none'
            />
            <br></br>
            <div className='divCheckbox'>
              <input
                type="checkbox"
                id="confirmReservation"
                name="confirmReservation"
                checked={this.state.confirmReservation}
                onChange={() => this.setState({ confirmReservation: !this.state.confirmReservation })}
              />
              <div className="slider-baqueira"></div>
              <label htmlFor="confirmReservation" className='textcheckbox-baqueira slide-checkbox'>
                {t('Acepto ceder mi correo electrónico para recibir la confirmación de mi reserva.')}
              </label>
            </div>
            <div className='divCheckbox'>
              <input
                type="checkbox"
                id="newsletterAcceptance"
                name="newsletterAcceptance"
                checked={this.state.newsletterAcceptance}
                onChange={() => this.setState({ newsletterAcceptance: !this.state.newsletterAcceptance })}
              />
              <div className="slider-baqueira"></div>
              <label htmlFor="newsletterAcceptance" className='textcheckbox-baqueira slide-checkbox'>
                {t('Acepto suscribirme al boletín informativo de Baqueira Beret para recibir contenido y notificaciones comerciales.')}

              </label>
            </div>
          </div>
          <br></br>
          <div className="row">
            <div
              className={`col lockerContinueButtonBaqueira ${this.isContinueDisabled() ? "disabled" : ""}`}
              onClick={(e) => this.goTo(1, e)}
            >
              <h1>{t("CONTINUAR")}</h1>
            </div>
          </div>
          <br></br>
          <div className="lockTitle">
            <span className="text-vw-dark-baqueira">{t('*Los datos personales recabados no se usarán en ningún caso para ofrecer comunicados comerciales de ningún tipo si el cliente no se suscribe a la newsletter.')}</span>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: true
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch)
})

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(PhoneBaqueiraView));
