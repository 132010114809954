import {
  AVAILABILITY_REQUEST, AVAILABILITY_SUCCESS, AVAILABILITY_FAILURE,
  LOCKERS_REQUEST, LOCKERS_SUCCESS, LOCKERS_FAILURE,
  PREBOOKING_REQUEST, PREBOOKING_SUCCESS, PREBOOKING_FAILURE,
  REDSYS_REQUEST, REDSYS_SUCCESS, REDSYS_FAILURE,
  QRSALE_REQUEST, QRSALE_SUCCESS, QRSALE_FAILURE,
  BOOKING_REQUEST, BOOKING_SUCCESS, BOOKING_FAILURE,
  BOOKEMAIL_REQUEST, BOOKEMAIL_SUCCESS, BOOKEMAIL_FAILURE,
  CANCEL_PREBOOKING_REQUEST, CANCEL_PREBOOKING_SUCCESS, CANCEL_PREBOOKING_FAILURE,
  CUSTOMER_REQUEST, CUSTOMER_SUCCESS, CUSTOMER_FAILURE,
  QR_TOKEN_REQUEST, QR_TOKEN_SUCCESS, QR_TOKEN_FAILURE,
  REDSYS_PARAMS_REQUEST, REDSYS_PARAMS_SUCCESS, REDSYS_PARAMS_FAILURE
} from '../actions/qrs'

const INITIAL_STATE = {
  qr: null, customer: null, availability: null, lockers: null, prebooking: null, cancelprebooking: null, redsysparams: null, redsys: null, qrsale: null, booking: null, bookemail: null, error: null, loading: false
}

const qrsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AVAILABILITY_REQUEST:
      return {
        ...state,
        availability: null, error: null, loading: true
      }
    case AVAILABILITY_SUCCESS:
      return {
        ...state,
        availability: action.response, error: null, loading: false
      }
    case AVAILABILITY_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case LOCKERS_REQUEST:
      return {
        ...state,
        lockers: null, error: null, loading: true
      }
    case LOCKERS_SUCCESS:
      return {
        ...state,
        lockers: action.response, error: null, loading: false
      }
    case LOCKERS_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case PREBOOKING_REQUEST:
      return {
        ...state,
        prebooking: null, error: null, loading: true
      }
    case PREBOOKING_SUCCESS:
      return {
        ...state,
        prebooking: action.response, error: null, loading: false
      }
    case PREBOOKING_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case REDSYS_REQUEST:
      return {
        ...state,
        redsys: null, error: null, loading: true
      }
    case REDSYS_SUCCESS:
      return {
        ...state,
        redsys: action.response, error: null, loading: false
      }
    case REDSYS_FAILURE:
      return {
        ...state,
        redsys: action.error, loading: false
      }
    case REDSYS_PARAMS_REQUEST:
      return {
        ...state,
        redsysparams: null, error: null, loading: true
      }
    case REDSYS_PARAMS_SUCCESS:
      return {
        ...state,
        redsysparams: action.response, error: null, loading: false
      }
    case REDSYS_PARAMS_FAILURE:
      return {
        ...state,
        redsysparams: action.error, loading: false
      }
    case QRSALE_REQUEST:
      return {
        ...state,
        qrsale: null, error: null, loading: true
      }
    case QRSALE_SUCCESS:
      return {
        ...state,
        qrsale: action.response, error: null, loading: false
      }
    case QRSALE_FAILURE:
      return {
        ...state,
        qrsale: action.error, loading: false
      }
    case BOOKING_REQUEST:
      return {
        ...state,
        booking: null, error: null, loading: true
      }
    case BOOKING_SUCCESS:
      return {
        ...state,
        booking: action.response, error: null, loading: false
      }
    case BOOKING_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case CANCEL_PREBOOKING_REQUEST:
      return {
        ...state,
        cancelprebooking: null, error: null, loading: true
      }
    case CANCEL_PREBOOKING_SUCCESS:
      return {
        ...state,
        cancelprebooking: action.response, error: null, loading: false
      }
    case CANCEL_PREBOOKING_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case BOOKEMAIL_REQUEST:
      return {
        ...state,
        bookemail: null, error: null, loading: true
      }
    case BOOKEMAIL_SUCCESS:
      return {
        ...state,
        bookemail: action.response, error: null, loading: false
      }
    case BOOKEMAIL_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case CUSTOMER_REQUEST:
      return {
        ...state,
        customer: null, error: null, loading: true
      }
    case CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: action.response, error: null, loading: false
      }
    case CUSTOMER_FAILURE:
      return {
        ...state,
        customer: action.error, loading: false
      }
    case QR_TOKEN_REQUEST:
      return {
        ...state,
        qr: null, error: null, loading: true
      }
    case QR_TOKEN_SUCCESS:
      return {
        ...state,
        qr: action.response, error: null, loading: false
      }
    case QR_TOKEN_FAILURE:
      return {
        ...state,
        qr: action.error, loading: false
      }
    default:
      return state
  }
}

export default qrsReducer

