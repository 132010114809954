import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { history } from '../store'
import { push } from 'react-router-redux'
import AppBaqueiraHeader from '../containers/AppBaqueiraHeader'
import { withNamespaces } from "react-i18next"
import { fetchDisponibility, fetchAvailability } from '../actions/qrsbaqueira'
import StepBarBaqueira from '../components/StepBarBaqueira'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB"; // Import the locale

registerLocale("en-GB", enGB); // Register the locale

class LockerSelectorBaqueiraView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      step: 1,
      lockers: null,
      prices: null,
      selectedLockerType: null,
      selectedQuantity: null,
      startDate: null,
      endDate: null,
      selectedInfo: null,  // Stores selected information for display
      qrType: "pay",
      errorMessage: false,
      isDurationExceeded: false,
    };

    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  componentDidMount() {
    const { zoneSelected, qr } = this.props.location.state;
    const qrtoken = qr.toString() + zoneSelected.toString();

    this.props.fetchAvailability(qrtoken)
      .then((answer) => {
        const answerLockers = answer.response.answerLockers;
        this.setState({
          lockers: answerLockers.Disponibility,
          prices: answerLockers.Prices,
          qrType: answerLockers.qrType
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  selectLockerType(lockerType) {
    const lockerPrice = this.getLockerPrice(lockerType);
    const { zoneSelected, descriptionZone } = this.props.location.state;

    this.setState({
      selectedLockerType: lockerType,
      selectedInfo: { lockerType, lockerPrice, zoneSelected, descriptionZone },
      step: 2
    });
  }

  handleQuantityChange(event) {
    this.setState({ selectedQuantity: event.target.value });
  }

  handleStartDateChange(date) {
    const { endDate } = this.state;
    const localDate = new Date(date.setHours(15, 0, 0, 0)); // Set to the start of the day in local time

    if (endDate) {
      const differenceInDays = Math.ceil((endDate - localDate) / (1000 * 60 * 60 * 24));
      this.setState({
        startDate: localDate,
        isDurationExceeded: differenceInDays >= 7, // Show message if duration exceeds 7 days
      });
    } else {
      this.setState({ startDate: localDate });
    }
  }

  handleEndDateChange(date) {
    const { startDate } = this.state;
    const localDate = new Date(date.setHours(15, 0, 0, 0)); // Set to the start of the day in local time

    if (startDate) {
      const differenceInDays = Math.ceil((localDate - startDate) / (1000 * 60 * 60 * 24));
      this.setState({
        endDate: localDate,
        isDurationExceeded: differenceInDays >= 7, // Show message if duration exceeds 7 days
      });
    } else {
      this.setState({ endDate: localDate });
    }
  }

  isAfterCutoffTime() {
    const nowInSpain = new Date().toLocaleString("en-US", { timeZone: "Europe/Madrid" });
    const currentHourInSpain = new Date(nowInSpain).getHours();
    return currentHourInSpain >= 18;
  }

  handleContinue() {
    const { selectedLockerType, selectedQuantity, startDate, endDate, qrType } = this.state;
    const { zoneSelected, descriptionZone, qr } = this.props.location.state;

    const bookingData = {
      Zone: zoneSelected,
      LockerType: selectedLockerType,
      LockerQty: selectedQuantity,
      RentalStartDate: startDate,
      RentalFinishDate: endDate,
      PaymentMethod: 'TPV'
    };

    this.props.fetchDisponibility(bookingData)
      .then((answer) => {
        if (answer.response.LockersDisponibility === true) {

          const lockerAssigned = answer.response.LockersAssigned
          const lockerAmount = answer.response.LockersAmount
          const prerentCode = answer.response.PrerentCode
          const prerentTime = answer.response.PrerentTime
          const totalDays = answer.response.TotalDays

          this.props.push('/PhoneBaqueiraView', {
            zoneSelected: zoneSelected,
            descriptionZone: descriptionZone,
            selectedLocker: selectedLockerType,
            lockerPrice: lockerAmount,
            lockerAssigned: lockerAssigned,
            prerentCode: prerentCode,
            prerentTime: prerentTime,
            totalDays: totalDays,
            startDate: startDate,
            endDate: endDate,
            qrType: qrType,
            qr: qr,
          })
        } else {
          this.setState({ errorMessage: true });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getLockerType(type) {
    const { t, i18n } = this.props

    switch (type) {
      case "BNS":
        return t('BÁSICA')
      case "CNS":
        return t('BÁSICA CARGA')
      case "BWS":
        return t('BÁSICA XL')
      case "CWS":
        return t('BÁSICA CARGA XL')
      case "BNM":
        return t('GRANDE')
      case "CNM":
        return t('GRANDE CARGA')
      case "BWM":
        return t('GRANDE XL')
      case "CWM":
        return t('GRANDE CARGA XL')
      case "BNL":
        return t('GRANDE')
      case "CNL":
        return t('GRANDE CARGA')
      case "BWL":
        return t('GRANDE XL')
      case "CWL":
        return t('GRANDE CARGA XL')
      case "BNXL":
        return t('GRANDE')
      case "CNXL":
        return t('GRANDE CARGA')
      case "BWXL":
        return t('GRANDE XL')
      case "CWXL":
        return t('GRANDE CARGA XL')
      case "BN2XL":
        return t('GRANDE')
      case "CN2XL":
        return t('GRANDE CARGA')
      case "BW2XL":
        return t('GRANDE XL')
      case "CW2XL":
        return t('GRANDE CARGA XL')
      case "BN3XL":
        return t('2 EQUIPOS DE ESQUÍ')
      case "CN3XL":
        return t('3 EQUIPOS DE ESQUÍ')
      case "BW3XL":
        return t('4 EQUIPOS DE ESQUÍ')
      case "CW3XL":
        return t('SKI CARGA XL')
      default:
        return t('BÁSICA')
    }
  }

  getLockerPicture(type) {
    return `/images/locker_baqueira_${type}.png`
  }

  getLockerPrice(type) {
    const { prices } = this.state;
    const lockerPriceObj = prices.find((price) => price.item === `LOCKER${type}`);
    return lockerPriceObj ? Number(lockerPriceObj.price).toFixed(2) : "0.00";
  }

  renderLockers() {
    const { t, i18n } = this.props
    const { lockers, prices } = this.state;
    const { zoneSelected, descriptionZone } = this.props.location.state;
    const lockerDivs = []

    if (!lockers || !prices) {
      return (<div class="spinner-baqueira"><img src="/images/logo_baqueira.png" alt="Logo" /></div>)
    } else {
      Object.keys(lockers).forEach((lockerKey) => {
        const locker = lockers[lockerKey];

        lockerDivs.push(
          <div className="col-xs-12 titleh5" key={lockerKey}>
            <div className="lockButton" onClick={() => this.selectLockerType(lockerKey)}>
              <div className="row">
                <div className="colLockersBaqueira">
                  <img src={this.getLockerPicture(lockerKey)} alt={"locker_baqueira_" + lockerKey} className="lockerSmallImageBaqueira" />
                </div>
                <div className="col">
                  <h7 className="titleLockers">{t('Taquilla')}</h7>
                  <h5 className="col lockerTextDescription">{this.getLockerType(lockerKey)}</h5>
                </div>
              </div>
            </div>
          </div>
        );
      });

      if (lockerDivs.length === 0) {
        return (
          <div className="col-xs-12 titleh5">
            <div className="lockSoldOutButton" onClick={(e) => this.goBack(e)}>
              <h5>{t('TAQUILLAS AGOTADAS')}</h5>
              <h7>{t('Haz click aquí para volver a selección de zonas')}</h7>
              <div className="lockSoldOutImgContainer">
                <img className="lockSoldOutImg" src="/images/locker_BNS.png" alt="Locker" />
                <img className="lockSoldOutCross" src="/images/cross.png" alt="Cross" />
              </div>
            </div>
          </div>
        )
      }

      return (<div>{lockerDivs}</div>)
    }
  }

  handleBack(step) {

    this.setState({ errorMessage: false });

    switch (step) {
      case 1: this.setState({ selectedLockerType: null, selectedInfo: null, selectedQuantity: null }, () => {
        this.setState({ step: 1 });
      });
        break;

      case 2: this.setState({ selectedQuantity: null, startDate: null, endDate: null }, () => {
        this.setState({ step: 2 });
      });
        break;

      case 3: this.setState({ startDate: null, endDate: null }, () => {
        this.setState({ step: 3 });
      });
        break;

    }
  }

  handleForward(step) {
    switch (step) {
      case 1: if (this.state.selectedQuantity) {
        this.setState({ step: 3 });
      }
        break;

      case 2: if (this.state.startDate && this.state.endDate) {
        this.setState({ step: 4 });
      }
        break;

    }
  }

  renderStepContent() {
    const { t } = this.props;
    const { step, lockers, selectedLockerType, selectedQuantity, startDate, endDate, isDurationExceeded } = this.state;

    const isCutoffPassed = this.isAfterCutoffTime();

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
      <div className="d-flex justify-content-center align-items-center dates-baqueira">
        <input
          className="dates-baqueira"
          onClick={onClick}
          value={value}
          readOnly // Ensures the input is not editable, preventing the keyboard from appearing
          ref={ref}
          placeholder="DD/MM/YYYY"
        />
        <i className="fas fa-calendar ml-2"></i>
      </div>
    ));

    switch (step) {
      case 1:
        // Step 1: Select Locker Type
        return (
          <div className="lockerSelectionStep">
            {this.renderLockers()}
          </div>
        );

      case 2:
        // Step 2: Select Quantity
        return (
          <div className="quantitySelectionStep">
            <div className="d-flex justify-content-center align-items-center">
              <select class="quantity-baqueira text-center" value={selectedQuantity} onChange={this.handleQuantityChange}>
                <option key={null} value={null}>{t('Selecciona...')}</option>
                {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
                  <option key={num} value={num}>{num} {num > 1 ? t('Taquillas') : t('Taquilla')}</option>
                ))}
              </select>
            </div>
            <br></br>
            <div className='d-flex justify-content-around align-content-center'>
              <button className="lockerBackButtonBaqueira" onClick={() => this.handleBack(1)}>
                {t("Atrás")}
              </button>
              <button disabled={!selectedQuantity} className={`lockerContinueButtonBaqueira ${!selectedQuantity && 'disabled-baqueira'}`} onClick={() => this.handleForward(1)}>
                {t("Continuar")}
              </button>
            </div>
          </div>
        );

      case 3:
        // Step 3: Select Dates
        return (
          <div className="dateSelectionStep">
            {isCutoffPassed && (
              <div className="text-center text-danger mb-3">
                <i className="fas fa-clock mr-2"></i>
                {t("El día actual no está disponible para selección después de las 18:00 hora local.")}
              </div>
            )}
            {isDurationExceeded && (
              <div className="text-center text-danger mb-3">
                <i className="fas fa-exclamation-triangle mr-2"></i>
                {t(
                  "El alquiler online está limitado a 7 días consecutivos, para realizar reservas de larga duración diríjase al staff de Baqueira Store o solicítelo via email a skiservice@baqueira.es"
                )}
              </div>
            )}
            <div className="">
              <div className="col text-center">
                <label className="d-block">{t("Fecha de Inicio")}</label>
                <DatePicker
                  selected={startDate}
                  onChange={this.handleStartDateChange}
                  dateFormat="yyyy-MM-dd"
                  minDate={isCutoffPassed ? new Date().setDate(new Date().getDate() + 1) : new Date()}
                  locale="en-GB"
                  customInput={<CustomInput />}
                  placeholderText="DD/MM/YYYY"
                />
              </div>
              <div className="col text-center mt-3">
                <label className="d-block">{t("Fecha de Fin")}</label>
                <DatePicker
                  selected={endDate}
                  onChange={this.handleEndDateChange}
                  dateFormat="yyyy-MM-dd"
                  minDate={startDate}
                  disabled={!startDate}
                  locale="en-GB"
                  customInput={<CustomInput />}
                  placeholderText="DD/MM/YYYY"
                />
              </div>
            </div>
            <br></br>
            <div className='d-flex justify-content-around align-content-center'>
              <button className="lockerBackButtonBaqueira" onClick={() => this.handleBack(2)}>
                {t("Atrás")}
              </button>
              <button disabled={!endDate || isDurationExceeded} className={`lockerContinueButtonBaqueira ${!endDate || isDurationExceeded && 'disabled-baqueira'}`} onClick={() => this.handleForward(2)}>
                {t("Continuar")}
              </button>
            </div>
          </div>
        );

      case 4:
        // Step 4: Show Continue Button
        return (
          <div className='text-center'>
            {this.state.errorMessage &&
              <span className="text-danger">{t("LO SENTIMOS PERO ESTAS FECHAS NO ESTÁN DISPONIBLES, PRUEBA OTRAS U OTRO TIPO DE TAQUILLAS.")}</span>
            }
            <div className='d-flex justify-content-around align-content-center'>
              <button className="lockerBackButtonBaqueira" onClick={() => this.handleBack(3)}>
                {t("Atrás")}
              </button>
              <button className="lockerContinueButtonBaqueira" onClick={() => this.handleContinue()} disabled={this.state.errorMessage}>
                {t("Confirmar")}
              </button>
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  renderSelectedInfo() {
    const { selectedInfo, selectedQuantity, startDate, endDate, step, selectedLockerType } = this.state;
    const { zoneSelected, descriptionZone } = this.props.location.state;
    const { t } = this.props;

    return (
      <div>
        <p className="lockTitleBaqueira">
          <span className="text-vw-light-baqueira">{t("ZONA")}: </span>
          <span className="text-vw-dark-baqueira">{zoneSelected} - {descriptionZone}</span>
        </p>
        {step >= 2 &&
          <p className="lockTitleBaqueira">
            <span className="text-vw-light-baqueira">{t("TIPO")}: </span>
            <span className="text-vw-dark-baqueira">{this.getLockerType(selectedLockerType)}</span>
          </p>
        }
        {step >= 3 &&
          <p className="lockTitleBaqueira">
            <span className="text-vw-light-baqueira">{t("CANTIDAD")}: </span>
            <span className="text-vw-dark-baqueira">{selectedQuantity}</span>
          </p>
        }
        {step >= 4 &&
          <p className="lockTitleBaqueira">
            <span className="text-vw-light-baqueira">{t("FECHAS")}: </span>
            <span className="text-vw-dark-baqueira">{startDate ? startDate.toLocaleDateString() : ""} - {endDate ? endDate.toLocaleDateString() : ""}</span>
          </p>
        }
      </div>
    );
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <AppBaqueiraHeader />
        <div className="lockerContainer">
          <br></br>
          <StepBarBaqueira step={2} />
          <br></br>
          {this.renderSelectedInfo()}
          <h3 className="lockTitle">
            <span className="text-vw-dark-baqueira">
              {t(
                this.state.step === 1 ? 'SELECCIONA EL TIPO DE TAQUILLA' :
                  this.state.step === 2 ? 'SELECCIONA LA CANTIDAD' :
                    this.state.step === 3 ? 'SELECCIONA LAS FECHAS' : 'REVISA Y CONFIRMA'
              )}
            </span>
          </h3>
          <br></br>
          {this.renderStepContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: true,
  lockers: state.lockers,
  prices: state.prices
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  fetchDisponibility: bindActionCreators(fetchDisponibility, dispatch),
  fetchAvailability: bindActionCreators(fetchAvailability, dispatch),
})

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(LockerSelectorBaqueiraView));
