import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { history } from '../store'
import { withNamespaces } from "react-i18next"
import { fetchQrByToken } from '../actions/qrs'
import AppHeader from '../containers/AppHeader'

class HomeView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      forwardScreen: false
    }

    this.goToZones = this.goToZones.bind(this);
    this.goToOTO = this.goToOTO.bind(this);
  }

  goToZones(e) {
    const { id } = this.props.match.params;
    const { qr } = this.props;
    
    this.setState(
      {
        forwardScreen: true,
      },
      () => {
        console.log("forwardScreen: ", this.state.forwardScreen);
        history.push(`/zoneSelectorView/${id}`, {
          qrMode: qr.Mode
        });
      }
    );
  }

  goToBaqueiraZones(e) {
    const { id } = this.props.match.params;
    const { qr } = this.props;
    
    this.setState(
      {
        forwardScreen: true,
      },
      () => {
        console.log("forwardScreen: ", this.state.forwardScreen);
        history.push(`/zoneSelectorBaqueiraView/${id}`, {
          qrMode: qr.Mode
        });
      }
    );
  }

  goToOTO(e) {
    const { id } = this.props.match.params;
    const { qr } = this.props;

    this.setState(
      {
        forwardScreen: true,
      },
      () => {
        console.log("forwardScreen: ", this.state.forwardScreen);
        history.push(`/otoSelectorView/${id}`, {
          qrMode: qr.Mode
        });
      }
    );
  }

  getBrowserLanguage() {
    let language = navigator.language || navigator.userLanguage;

    return language.split('-')[0]
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const browserLanguage = this.getBrowserLanguage();
    this.props.i18n.changeLanguage(browserLanguage);
    this.props.fetchQrByToken(id);
  }

  componentDidUpdate(prevProps) {
    // Check if qr prop has changed
    if (this.props.qr !== prevProps.qr && this.props.qr) {
      const { qr } = this.props;

      console.log("API response:", qr)

      // Access qr.Mode safely after the data is fetched and qr is defined
      switch (qr.Mode) {
        case 0:
          this.goToZones();
          break;
        case 1:
          this.goToOTO();
          break;
        case 2:
            this.goToBaqueiraZones();
            break;
        default:
          console.error("Unexpected qr.Mode value or qr is undefined");
      }
    }
  }

  render() {
    const { t, i18n, qr } = this.props

    if (!qr) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (
      <div>
        <AppHeader />
        <div className="lockerContainer mt-5">
          <h3 className="lockTitle">
            <span className="text-vw-dark">{t('PARECE QUE ALGO HA FALLADO')}</span>
          </h3>
          <div className="col-xs-12">
            <img src="/images/incidences.svg" alt="Error!" className="lockerAccessibilityImg" />
          </div>
          <h3 className="lockTitle">
            <span className="text-vw-dark">{t('VUELVE A ESCANEAR EL QR')}</span>
          </h3>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: true,
  qr: state.qrs.qr
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  fetchQrByToken: bindActionCreators(fetchQrByToken, dispatch)
})

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(HomeView));