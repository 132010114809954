import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import { history } from '../store'
import AppBaqueiraHeader from '../containers/AppBaqueiraHeader'
import { withNamespaces } from "react-i18next";
import { postRedsys } from '../actions/qrs'
import { fetchCustomer } from '../actions/qrs'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import StepBarBaqueira from '../components/StepBarBaqueira'

class PaymentBaqueiraView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      redsys: null,
      iva: 21,
      redsysSignature: null,
      redsysMerchant: null,
      redsysVersion: null,
      total: null,
      importeBase: null,
      importeIva: null,
      minutes: 5,
      seconds: 0,
      isTimeUp: false,
      newLockerPrice: 0,
      discountApplied: false
    }

    this.toggle = this.toggle.bind(this);
    this.getLockerType = this.getLockerType.bind(this);

  }

  componentDidMount() {

    const {
      zoneSelected,
      descriptionZone,
      selectedLocker,
      lockerPrice,
      lockerAssigned,
      prerentCode,
      prerentTime,
      totalDays,
      startDate,
      endDate,
      qrType,
      qr,
      customerName,
      mail,
      phoneNumber,
      newsletterAcceptance,
      confirmReservation
    } = this.props.location.state;

    const { t, i18n } = this.props

    //Idiomas Redsys - Castellano-001, Inglés-002, Catalán-003, Francés-004, Alemán-005, Holandés-006, Italiano-007, Sueco-008, Portugués-009, Valenciano-010, Polaco-011, Gallego-012 y Euskera-013.
    console.log("Language: " + i18n.language);

    let language = '001'

    switch (i18n.language) {
      case "es": language = '001'; break;
      case "en": language = '002'; break;
      case "it": language = '007'; break;
      case "gr": language = '005'; break;
      case "fr": language = '004'; break;
      case "pt": language = '009'; break;
    }

    //Discount temporary

    let newLockerPrice = lockerPrice;

    let discountApplied = false;

    // Ensure lockerPrice is based on 5-day rate if totalDays > 5
    if (totalDays > 5) {
      const dailyRate = lockerPrice / totalDays; // Calculate the daily rate based on 5-day price
      newLockerPrice = dailyRate * 5; // Reduce the price for the excess days
      discountApplied = true;
    }

    // Update the state with discount status and adjusted locker price
    this.setState({ newLockerPrice, discountApplied });

    const minutes = Math.floor(prerentTime / 60);
    const seconds = prerentTime % 60;

    this.setState({ minutes: minutes, seconds: seconds }, () => {
      this.startCountdown();;
    });

    let redsysPetition = {}

    let paymentId = Math.floor((Math.random() * 1000000) + 1).toString()

    let redsysURLOK = `https://lockers-qr.vacway.es/TicketBaqueiraView?descriptionZone=${descriptionZone}&zoneSelected=${zoneSelected}&selectedLocker=${selectedLocker}&lockerAssigned=${lockerAssigned}&lockerPrice=${newLockerPrice}&paymentId=${paymentId}&qrToken=${qr}&language=${i18n.language}&prerentCode=${prerentCode}&startDate=${startDate.toLocaleDateString()}&endDate=${endDate.toLocaleDateString()}&customerName=${customerName}&mail=${mail}&phoneNumber=${phoneNumber}`
    let redsysURLKO = `https://lockers-qr.vacway.es/PaymentBaqueiraError?qr=${qr}&language=${i18n.language}`

    redsysPetition.price = Number(newLockerPrice.toFixed(2))
    redsysPetition.redsysURLOK = redsysURLOK
    redsysPetition.redsysURLKO = redsysURLKO
    redsysPetition.paymentId = paymentId

    redsysPetition.merchantCode = '351639505'
    redsysPetition.currency = '978'
    redsysPetition.terminal = '1'
    redsysPetition.merchantUrl = 'https://vacway.com'
    redsysPetition.secretKey = 'xhI+J4wrx0JKkeGqJ12j2+ErLNH9OpHM'
    redsysPetition.language = language

    this.props.fetchCustomer('6669b7f41628a3b21f4ceaa0')
      .then((customer) => {

        redsysPetition.merchantCode = customer.response.Redsys.MERCHANTCODE
        redsysPetition.currency = customer.response.Redsys.CURRENCY
        redsysPetition.terminal = customer.response.Redsys.TERMINAL
        redsysPetition.merchantUrl = customer.response.Redsys.MERCHANTURL
        redsysPetition.secretKey = customer.response.Redsys.SECRETKEY

        console.log("redsysPetition: ", redsysPetition);

        this.props.postRedsys(redsysPetition)
          .then((answer) => {
            let answerRedsys = answer.response

            let redsysSignature = answerRedsys.Ds_Signature
            let redsysMerchant = answerRedsys.Ds_MerchantParameters
            let redsysVersion = answerRedsys.Ds_SignatureVersion

            this.TotalComponent();

            this.setState({ redsys: answerRedsys, redsysSignature: redsysSignature, redsysMerchant: redsysMerchant, redsysVersion: redsysVersion });

          })
          .catch((error) => {
            console.error(error);
          });

      })
      .catch((error) => {
        console.error(error);
      });

  }

  componentWillUnmount() {
    const { qr, lockerAssigned } = this.props.location.state;

    clearInterval(this.countdownInterval);

    history.push(`/qrsale/${qr}?lockerAssigned=${lockerAssigned}`)
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  goTo(number, e) {
    history.push(`/phoneView`)
  }

  handleRedirect = () => {
    const { qr, lockerAssigned } = this.props.location.state;
    history.push(`/qrsale/${qr}?lockerAssigned=${lockerAssigned}`)
  };

  startCountdown = () => {
    this.countdownInterval = setInterval(() => {
      if (this.state.seconds > 0) {
        this.setState((prevState) => ({ seconds: prevState.seconds - 1 }));
      } else {
        if (this.state.minutes > 0) {
          this.setState((prevState) => ({
            minutes: prevState.minutes - 1,
            seconds: 59,
          }));
        } else {
          clearInterval(this.countdownInterval);
          // Here you can add any logic you want to perform after the countdown reaches 0
          this.setState({ isTimeUp: true });
        }
      }
    }, 1000);
  };

  TotalComponent() {
    const { iva, newLockerPrice } = this.state;

    let importeBase = (newLockerPrice / (1 + (iva / 100))).toFixed(2)
    let importeIva = (newLockerPrice - importeBase).toFixed(2)
    let total = newLockerPrice.toFixed(2)

    this.setState({ importeBase: importeBase, importeIva: importeIva, total: total });

    return { total, importeBase, importeIva, iva };
  }

  getLockerType(type) {

    const { t, i18n } = this.props

    switch (type) {

      case "BNS":
        return t('BÁSICA')
      case "CNS":
        return t('BÁSICA CARGA')
      case "BWS":
        return t('BÁSICA XL')
      case "CWS":
        return t('BÁSICA CARGA XL')
      case "BNM":
        return t('GRANDE')
      case "CNM":
        return t('GRANDE CARGA')
      case "BWM":
        return t('GRANDE XL')
      case "CWM":
        return t('GRANDE CARGA XL')
      case "BNL":
        return t('GRANDE')
      case "CNL":
        return t('GRANDE CARGA')
      case "BWL":
        return t('GRANDE XL')
      case "CWL":
        return t('GRANDE CARGA XL')
      case "BNXL":
        return t('GRANDE')
      case "CNXL":
        return t('GRANDE CARGA')
      case "BWXL":
        return t('GRANDE XL')
      case "CWXL":
        return t('GRANDE CARGA XL')
      case "BN2XL":
        return t('GRANDE')
      case "CN2XL":
        return t('GRANDE CARGA')
      case "BW2XL":
        return t('GRANDE XL')
      case "CW2XL":
        return t('GRANDE CARGA XL')
      case "BN3XL":
        return t('2 EQUIPOS DE ESQUÍ')
      case "CN3XL":
        return t('3 EQUIPOS DE ESQUÍ')
      case "BW3XL":
        return t('4 EQUIPOS DE ESQUÍ')
      case "CW3XL":
        return t('SKI CARGA XL')
      default:
        return t('BÁSICA')
    }
  }

  redsysForm() {

    const { redsys, redsysSignature, redsysMerchant, redsysVersion, minutes, seconds, newLockerPrice } = this.state;
    const { descriptionZone, zoneSelected, selectedLocker, lockerPrice, lockerAssigned, qr, qrType, prerentCode, startDate, endDate, customerName, mail, phoneNumber } = this.props.location.state;

    const { t, i18n } = this.props

    const paymentId = Math.floor((Math.random() * 1000000) + 1).toString();
    const productionUrl = "https://sis.redsys.es/sis/realizarPago"
    const testUrl = "https://sis-t.redsys.es:25443/sis/realizarPago"

    const iappaURL = `https://lockers-qr.vacway.es/PhoneView?descriptionZone=${descriptionZone}&zoneSelected=${zoneSelected}&selectedLocker=${selectedLocker}&lockerAssigned=${lockerAssigned}&lockerPrice=${newLockerPrice}&paymentId=${paymentId}&qrToken=${qr}&qrType=${qrType}`

    let redsysURLOK = `https://lockers-qr.vacway.es/TicketBaqueiraView?descriptionZone=${descriptionZone}&zoneSelected=${zoneSelected}&selectedLocker=${selectedLocker}&lockerAssigned=${lockerAssigned}&lockerPrice=${newLockerPrice}&paymentId=${paymentId}&qrToken=${qr}&language=${i18n.language}&prerentCode=${prerentCode}&startDate=${startDate.toLocaleDateString()}&endDate=${endDate.toLocaleDateString()}&customerName=${customerName}&mail=${mail}&phoneNumber=${phoneNumber}`

    const handleButtonClick = () => {
      window.location.href = redsysURLOK;
    };

    if (!redsys || !redsysSignature || !redsysMerchant || !redsysVersion) {
      return (<div class="spinner-baqueira"><img src="/images/logo_baqueira.png" alt="Logo" /></div>)
    } else {

      if (qrType === "pay") {
        return (
          <html>
            <body>
              <form action={productionUrl} method="POST" accept-charset="UTF-8" name="peticionRedsys">
                <input type="hidden" name="Ds_SignatureVersion" value={`${redsysVersion}`} />
                <input type="hidden" name="Ds_MerchantParameters" value={`${redsysMerchant}`} />
                <input type="hidden" name="Ds_Signature" value={`${redsysSignature}`} />
                <input type="submit" value={t('PAGAR')} className="col lockerPayButtonBaqueira" />
              </form>
            </body>
          </html>
        );
      } else if (qrType === "free") {
        return (
          <html>
            <body>
              <form method="POST" accept-charset="UTF-8" name="peticionRedsys">
                <input type="hidden" name="Ds_SignatureVersion" value={`${redsysVersion}`} />
                <input type="hidden" name="Ds_MerchantParameters" value={`${redsysMerchant}`} />
                <input type="hidden" name="Ds_Signature" value={`${redsysSignature}`} />
                <input type="button" value={t('RESERVAR')} className="col lockerPayButtonBaqueira" onClick={handleButtonClick} />
              </form>
            </body>
          </html>
        );
      } else if (qrType === "test") {
        return (
          <html>
            <body>
              <form action={testUrl} method="POST" accept-charset="UTF-8" name="peticionRedsys">
                <input type="hidden" name="Ds_SignatureVersion" value={`${redsysVersion}`} />
                <input type="hidden" name="Ds_MerchantParameters" value={`${redsysMerchant}`} />
                <input type="hidden" name="Ds_Signature" value={`${redsysSignature}`} />
                <input type="submit" value={t('TEST')} className="col lockerPayButtonBaqueira" />
              </form>
            </body>
          </html>
        );
      } else {
        return (
          <html>
            <body>
              <form action={productionUrl} method="POST" accept-charset="UTF-8" name="peticionRedsys">
                <input type="hidden" name="Ds_SignatureVersion" value={`${redsysVersion}`} />
                <input type="hidden" name="Ds_MerchantParameters" value={`${redsysMerchant}`} />
                <input type="hidden" name="Ds_Signature" value={`${redsysSignature}`} />
                <input type="submit" value={t('PAGAR')} className="col lockerPayButtonBaqueira" />
              </form>
            </body>
          </html>
        );
      }
    }
  }

  render() {
    const { t, i18n } = this.props
    const { redsys, iva, importeBase, importeIva, total, minutes, seconds, isTimeUp, discountApplied } = this.state;
    const { descriptionZone, selectedLocker, lockerAssigned, startDate, endDate, customerName, mail, phoneNumber, lockerPrice } = this.props.location.state;

    if (!redsys) {
      return (<div class="spinner-baqueira"><img src="/images/logo_baqueira.png" alt="Logo" /></div>)
    } else {
      return (
        <div>
          <AppBaqueiraHeader />
          <div className="lockerContainer">
            <br></br>
            <StepBarBaqueira step={4} />
            <br></br>
            <h3 className="lockTitle">
              <span className="text-vw-dark-baqueira">{t('CONFIRMA TU COMPRA')}</span>
            </h3>
            <hr className="bg-vw-light-baqueira" />
            <div className="countdownTPV-baqueira">
              <p>{t('Taquilla reservada durante')}</p>
              {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
            </div>
            <br></br>
            <div className="lockerTicket-baqueira">
              <h5 className="lockTitle">
                <span className="text-vw-light-baqueira">{t('ZONA')}:  <br></br><span className="text-vw-dark-baqueira">{descriptionZone}</span></span>
              </h5>
              <h5 className="lockTitle">
                <span className="text-vw-light-baqueira">{t('RESERVA')}:  <br></br>
                  <span className="text-vw-dark-baqueira">{startDate ? startDate.toLocaleDateString() : ""} - {endDate ? endDate.toLocaleDateString() : ""}</span><br></br>
                  <span className="text-vw-dark-baqueira">{customerName}</span><br></br>
                  <span className="text-vw-dark-baqueira">{mail}</span><br></br>
                  <span className="text-vw-dark-baqueira">{phoneNumber}</span><br></br>
                </span>
              </h5>
              <h5 className="lockTitle">
                <span className="text-vw-light-baqueira">{t('TIPO DE TAQUILLA')}: <br></br><span className="text-vw-dark-baqueira">{this.getLockerType(selectedLocker)}</span></span>
              </h5>
              <h5 className="lockTitle">
                <span className="text-vw-light-baqueira">{t('CANTIDAD')}: <br></br><span className="text-vw-dark-baqueira">{lockerAssigned.length}</span></span>
              </h5>
            </div>
            <div className="lockerTicket-baqueira">
              {discountApplied &&
                <h6 className="lockTitle">
                  <span className="text-vw-light-baqueira">{t('Te hemos aplicado un descuento en el precio, solo vas a pagar 5 días de tu reserva!')}</span>
                </h6>
              }
              {discountApplied &&
                <h6 className="lockTitle">
                  <span className="text-vw-dark-baqueira">{t('IMPORTE ORIGINAL')}: {lockerPrice.toFixed(2)}€</span>
                </h6>
              }
              <h6 className="lockTitle">
                <span className="text-vw-dark-baqueira">{t('IVA')}: {iva}%</span>
              </h6>
              <h6 className="lockTitle">
                <span className="text-vw-dark-baqueira">{t('IMPORTE BASE')}: {importeBase}€</span>
              </h6>
              <h6 className="lockTitle">
                <span className="text-vw-dark-baqueira">{t('IMPORTE IVA')}: {importeIva}€</span>
              </h6>
              <hr className="bg-vw-dark" />
              <h3 className="lockTitle">
                <span className="text-vw-dark-baqueira">{t('TOTAL')}: {total}€</span>
              </h3>
            </div>
            {this.redsysForm()}
          </div>
          <div>
            <Modal isOpen={isTimeUp}>
              <ModalHeader>
                {t('Tiempo de reserva agotado')}
              </ModalHeader>
              <ModalBody>
                {t('Lo sentimos, pero no podemos reservar tu taquilla por más tiempo, otros usuarios deben poder acceder a ella. Vuelve a iniciar el proceso y finaliza la compra en el tiempo estipulado.')}
              </ModalBody>
              <ModalFooter>
                <Button color="warning" className="button-center"
                  onClick={this.handleRedirect}>
                  {t('Volver al inicio')}
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({
  auth: true,
  redsys: state.redsys,
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  postRedsys: bindActionCreators(postRedsys, dispatch),
  fetchCustomer: bindActionCreators(fetchCustomer, dispatch)
})

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(PaymentBaqueiraView));