import { CALL_API } from '../middleware/api'

export const AVAILABILITY_REQUEST = 'AVAILABILITY_REQUEST'
export const AVAILABILITY_SUCCESS = 'AVAILABILITY_SUCCESS'
export const AVAILABILITY_FAILURE = 'AVAILABILITY_FAILURE'

export function fetchAvailability(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `qrs/availability/${id}`,
      authenticated: true,
      types: [AVAILABILITY_REQUEST, AVAILABILITY_SUCCESS, AVAILABILITY_FAILURE]
    }
  }
}

export const LOCKERS_REQUEST = 'LOCKERS_REQUEST'
export const LOCKERS_SUCCESS = 'LOCKERS_SUCCESS'
export const LOCKERS_FAILURE = 'LOCKERS_FAILURE'

export function fetchLockers(id) {

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `qrs/disponibility/${id}`,
      authenticated: true,
      types: [LOCKERS_REQUEST, LOCKERS_SUCCESS, LOCKERS_FAILURE]
    }
  }
}

export const PREBOOKING_REQUEST = 'PREBOOKING_REQUEST'
export const PREBOOKING_SUCCESS = 'PREBOOKING_SUCCESS'
export const PREBOOKING_FAILURE = 'PREBOOKING_FAILURE'

export function postPrebooking(prebooking) {

  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'qrs/prebooking',
      authenticated: true,
      body: JSON.stringify(prebooking),
      types: [PREBOOKING_REQUEST, PREBOOKING_SUCCESS, PREBOOKING_FAILURE]
    }
  }
}

export const REDSYS_REQUEST = 'REDSYS_REQUEST'
export const REDSYS_SUCCESS = 'REDSYS_SUCCESS'
export const REDSYS_FAILURE = 'REDSYS_FAILURE'

export function postRedsys(price) {

  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'qrs/redsys',
      authenticated: true,
      body: JSON.stringify(price),
      types: [REDSYS_REQUEST, REDSYS_SUCCESS, REDSYS_FAILURE]
    }
  }
}

export const REDSYS_PARAMS_REQUEST = 'REDSYS_PARAMS_REQUEST'
export const REDSYS_PARAMS_SUCCESS = 'REDSYS_PARAMS_SUCCESS'
export const REDSYS_PARAMS_FAILURE = 'REDSYS_PARAMS_FAILURE'

export function postRedsysGetParams(params) {

  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'qrs/redsys-get-parameters',
      authenticated: true,
      body: JSON.stringify(params),
      types: [REDSYS_PARAMS_REQUEST, REDSYS_PARAMS_SUCCESS, REDSYS_PARAMS_FAILURE]
    }
  }
}

export const QRSALE_REQUEST = 'QRSALE_REQUEST'
export const QRSALE_SUCCESS = 'QRSALE_SUCCESS'
export const QRSALE_FAILURE = 'QRSALE_FAILURE'

export function postQrsale(sale) {

  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'sales/qr',
      authenticated: true,
      body: JSON.stringify(sale),
      types: [QRSALE_REQUEST, QRSALE_SUCCESS, QRSALE_FAILURE]
    }
  }
}

export const BOOKING_REQUEST = 'BOOKING_REQUEST'
export const BOOKING_SUCCESS = 'BOOKING_SUCCESS'
export const BOOKING_FAILURE = 'BOOKING_FAILURE'

export function postBooking(booking) {

  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'qrs/booking',
      authenticated: true,
      body: JSON.stringify(booking),
      types: [BOOKING_REQUEST, BOOKING_SUCCESS, BOOKING_FAILURE]
    }
  }
}

export const BOOKEMAIL_REQUEST = 'BOOKEMAIL_REQUEST'
export const BOOKEMAIL_SUCCESS = 'BOOKEMAIL_SUCCESS'
export const BOOKEMAIL_FAILURE = 'BOOKEMAIL_FAILURE'

export function postBookemail(bookemail) {

  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'qrs/bookemail',
      authenticated: true,
      body: JSON.stringify(bookemail),
      types: [BOOKEMAIL_REQUEST, BOOKEMAIL_SUCCESS, BOOKEMAIL_FAILURE]
    }
  }
}

export const CANCEL_PREBOOKING_REQUEST = 'CANCEL_PREBOOKING_REQUEST'
export const CANCEL_PREBOOKING_SUCCESS = 'CANCEL_PREBOOKING_SUCCESS'
export const CANCEL_PREBOOKING_FAILURE = 'CANCEL_PREBOOKING_FAILURE'

export function postCancelPrebooking(cancelprebooking) {

  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'qrs/cancelprebooking',
      authenticated: true,
      body: JSON.stringify(cancelprebooking),
      types: [CANCEL_PREBOOKING_REQUEST, CANCEL_PREBOOKING_SUCCESS, CANCEL_PREBOOKING_FAILURE]
    }
  }
}

export const CUSTOMER_REQUEST = 'CUSTOMER_REQUEST'
export const CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS'
export const CUSTOMER_FAILURE = 'CUSTOMER_FAILURE'

export function fetchCustomer(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `qrs/customer/${id}`,
      authenticated: true,
      types: [CUSTOMER_REQUEST, CUSTOMER_SUCCESS, CUSTOMER_FAILURE]
    }
  }
}


export const QR_TOKEN_REQUEST = 'QR_TOKEN_REQUEST'
export const QR_TOKEN_SUCCESS = 'QR_TOKEN_SUCCESS'
export const QR_TOKEN_FAILURE = 'QR_TOKEN_FAILURE'

export function fetchQrByToken(token) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `qrs/token/${token}`,
      authenticated: true,
      types: [QR_TOKEN_REQUEST, QR_TOKEN_SUCCESS, QR_TOKEN_FAILURE]
    }
  }
}