import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

// import translationEN from '../public/locales/en/translation.json';

// the translations
const resources = {
  en: {
    translation: {
      //Menu
      "Log in": "Login",
      "Exit": "Exit",
      //Step Bar
      "Zona": "Zone",
      "Taquilla": "Locker",
      "Pago": "Pay",
      "Email": "Email",
      //Home View
      "PARECE QUE ALGO HA FALLADO": "IT SEEMS SOMETHING HAS FAILED",
      "VUELVE A ESCANEAR EL QR": "SCAN THE QR AGAIN",
      //Zone Selector View
      "SELECCIONA LA ZONA DE TAQUILLAS": "SELECT THE LOCKERS ZONE",
      "BIENVENIDO A": "WELCOME TO",
      "CONSULTA EL MAPA DEL PARQUE": "CHECK THE MAP OF THE PARK",
      "ZONA ": "ZONE ",
      //Locker Selector View
      "SELECCIONA EL TIPO DE TAQUILLA": "SELECT THE TYPE OF LOCKER",
      "PARQUE": "PARK",
      "ZONA SELECCIONADA": "ZONE SELECTED",
      "Taquilla": "Locker",
      "BÁSICA": "STANDARD",
      "BÁSICA CARGA": "STANDARD CHARGER",
      "BÁSICA XL": "STANDARD XL",
      "BÁSICA CARGA XL": "STANDARD CHARGER XL",
      "GRANDE": "BIG",
      "GRANDE CARGA": "BIG CHARGER",
      "GRANDE XL": "BIG XL",
      "GRANDE CARGA XL": "BIG CHARGER XL",
      "SKI": "SKI",
      "SKI CARGA": "SKI CHARGER",
      "SKI XL": "SKI XL",
      "SKI CARGA XL": "SKI CHARGER XL",
      "TAQUILLAS AGOTADAS": "SOLD OUT LOCKERS",
      "Haz click aquí para volver a selección de zonas": "Click here to return to zone selection",
      //Accessibility View
      "¿NECESITAS UNA TAQUILLA ADAPTADA?": "DO YOU NEED AN ADAPTED LOCKER?",
      "Tienes la opción de seleccionar una taquilla de altura adaptada para tu confort.": "You have the option of selecting a height adapted locker for your comfort.",
      "NO": "NO",
      "SI": "YES",
      //Payment View
      "CONFIRMA TU COMPRA": "VERIFY YOUR ORDER",
      "Taquilla reservada durante": "Reserved locker during",
      "PARQUE": "PARK",
      "ZONA": "ZONE",
      "ACCESIBLE": "ACCESSIBLE",
      "RESERVA": "RESERVATION",
      "TIPO DE TAQUILLA": "LOCKER TYPE",
      "HASTA FINALIZAR EL DÍA": "UNTIL THE END OF THE DAY",
      "IVA": "VAT",
      "IMPORTE BASE": "BASE AMOUNT",
      "IMPORTE IVA": "VAT AMOUNT",
      "TOTAL": "TOTAL",
      "Tiempo de reserva agotado": "Reservation time out",
      "Lo sentimos, pero no podemos reservar tu taquilla por más tiempo, otros usuarios deben poder acceder a ella. Vuelve a iniciar el proceso y finaliza la compra en el tiempo estipulado.": "We are sorry, but we cannot reserve your locker for longer, other users must be able to access it. Start the process again and complete the purchase within the stipulated time.",
      "Volver al inicio": "Back to start",
      "PAGAR": "PAY",
      "RESERVAR": "RENT",
      //Payment Error View
      "ERROR": "ERROR",
      "No se ha podido realizar la compra. Vuelve a intentarlo más tarde.": "The purchase could not be made. Try again later.",
      "VOLVER": "RETURN TO",
      "AL INICIO": "START",
      //Phone View
      "¡TU TAQUILLA HA SIDO RESERVADA!": "YOUR LOCKER HAS BEEN RESERVED!",
      "FACILITANOS TUS DATOS DE RESPALDO": "PROVIDE US WITH YOUR BACKUP DATA",
      "Tu teléfono se vinculará a la taquilla reservada para poder recuperar el Pin de esta en caso de que lo pierdas. Al email te llegará la confirmación de tu reserva.": "Your phone will be linked to the reserved locker to be able to recover its Pin in case you lose it. You will receive confirmation of your reservation by email.",
      "TELÉFONO*": "PHONE NUMBER*",
      "EMAIL*": "EMAIL*",
      "Acepto ceder mi correo electrónico para recibir la confirmación de mi reserva.": "I agree to give my email to receive confirmation of my reservation.",
      "Acepto suscribirme al boletín informativo de VACWAY para recibir contenido y notificaciones comerciales.": "I agree to subscribe to the VACWAY newsletter to receive commercial content and notifications.",
      "CONTINUAR": "CONTINUE",
      "*Los datos personales recabados no se usarán en ningún caso para ofrecer comunicados comerciales de ningún tipo si el cliente no se suscribe a la newsletter.": "*The personal data collected will not be used in any case to offer commercial communications of any kind if the client does not subscribe to the newsletter.",
      //Ticket View
      "¡GRACIAS POR RESERVAR TU TAQUILLA!": "THANK YOU FOR RESERVING YOUR LOCKER!",
      "Sigue las instrucciones que encontrarás a continuación o en tu email.": "Follow the instructions that you will find below or in your email.",
      "TICKET": "TICKET",
      "Nº TAQUILLA": "LOCKER No.",
      "PIN": "PIN",
      "ZONA": "ZONE",
      "TIPO": "TYPE",
      "UBICACIÓN": "LOCATION",
      "FACTURA": "INVOICE",
      "*Si deseas obtener el Pin en formato físico para llevarlo durante tu estancia en el parque contacta con los responsables de la zona de taquillas y enséñales tu email de reserva.": "*If you want to obtain the Pin in physical format to carry it during your stay in the park, contact the people in charge of the ticket office area and show them your reservation email.",
      "DESCARGAR TICKET": "DOWNLOAD TICKET",
      "INSTRUCCIONES": "INSTRUCTIONS",
      "PASO 1": "STEP 1",
      "LOCALIZA TU TAQUILLA": "LOCATE YOUR LOCKER",
      "PASO 2": "STEP 2",
      "TOCA EL TECLADO": "TOUCH THE KEYBOARD",
      "PASO 3": "STEP 3",
      "INTRODUCE TU PIN": "INSERT YOUR PIN",
      "PASO 4": "STEP 4",
      "ABRE LAS VECES QUE QUIERAS": "OPEN AS MANY TIMES AS YOU WANT",
      //Ticket in Pdf (Ticket View)
      "Más información y garantía en www.vacway.com": "More information and warranty at www.vacway.com",
      "Factura Simplificada": "Simplified invoice",
      "INFORMACIÓN DE TU LOCKER:": "YOUR LOCKER INFORMATION:",
      "Producto": "Product",
      "UNIDADES": "Units",
      "Fecha y Hora": "Date and Time",
      //LockerSelectorBaqueiraView
      "2 EQUIPOS DE ESQUÍ": "2 SKI SETS",
      "3 EQUIPOS DE ESQUÍ": "3 SKI SETS",
      "4 EQUIPOS DE ESQUÍ": "4 SKI SETS",
      "Selecciona...": "Select...",
      "Taquillas": "Lockers",
      "Atrás": "Back",
      "Continuar": "Next",
      "Fecha de Inicio": "Start Date",
      "Fecha de Fin": "End Date",
      "LO SENTIMOS PERO ESTAS FECHAS NO ESTÁN DISPONIBLES, PRUEBA OTRAS U OTRO TIPO DE TAQUILLAS.": "SORRY, THESE DATES ARE NOT AVAILABLE. TRY OTHER DATES OR ANOTHER TYPE OF LOCKER.",
      "ZONA": "ZONE",
      "TIPO": "TYPE",
      "CANTIDAD": "QUANTITY",
      "FECHAS": "DATES",
      "SELECCIONA EL TIPO DE TAQUILLA": "SELECT THE TYPE OF LOCKER",
      "SELECCIONA LA CANTIDAD": "SELECT THE QUANTITY",
      "SELECCIONA LAS FECHAS": "SELECT THE DATES",
      "REVISA Y CONFIRMA": "REVIEW AND CONFIRM",
      "El día actual no está disponible para selección después de las 18:00 hora local.": "The current day is not available for selection after 18:00 local time.",
      "El alquiler online está limitado a 7 días consecutivos, para realizar reservas de larga duración diríjase al staff de Baqueira Store o solicítelo via email a skiservice@baqueira.es": "Online rentals are limited to 7 consecutive days, for long-term reservations please contact the Baqueira Store staff or request it via email at skiservice@baqueira.es.",
      //PhoneBaqueiraView
      "NOMBRE COMPLETO*": "COMPLETE NAME*",
      //TicketBaqueiraView
      "Disponible en tu email que se enviará el día de inicio de tu reserva": "Available in your email, which will be sent on the start date of your reservation",
      "FECHA COMPRA": "PURCHASE DATE",
      //PaymentBaqueiraView
      "Te hemos aplicado un descuento en el precio, solo vas a pagar 5 días de tu reserva!": "We have applied a discount to the price; you will only pay for 5 days of your reservation!",
      "IMPORTE ORIGINAL": "ORIGINAL AMOUNT"
    }
  },
  it: {
    translation: {
      //Menu
      "Log in": "Accedi",
      "Exit": "Uscita",
      //Step Bar
      "Zona": "Zona",
      "Taquilla": "Armadietto",
      "Pago": "Pagamento",
      "Email": "Email",
      //Home View
      "PARECE QUE ALGO HA FALLADO": "IT SEEMS SOMETHING HAS FAILED",
      "VUELVE A ESCANEAR EL QR": "SCAN THE QR AGAIN",
      //Zone Selector View
      "SELECCIONA LA ZONA DE TAQUILLAS": "SELEZIONA LA ZONA DEGLI ARMADIETTI",
      "BIENVENIDO A": "BENVENUTO A",
      "CONSULTA EL MAPA DEL PARQUE": "CONSULTA LA MAPPA DEL PARCO",
      "ZONA ": "ZONA ",
      //Locker Selector View
      "SELECCIONA EL TIPO DE TAQUILLA": "SELEZIONA IL TIPO DI ARMADIETTO",
      "PARQUE": "PARCO",
      "ZONA SELECCIONADA": "ZONA SELEZIONATA",
      "Taquilla": "Armadietto",
      "BÁSICA": "BASE",
      "BÁSICA CARGA": "BASE CON CARICATORE",
      "BÁSICA XL": "BASE XL",
      "BÁSICA CARGA XL": "BASE CON CARICATORE XL",
      "GRANDE": "GRANDE",
      "GRANDE CARGA": "GRANDE CON CARICATORE",
      "GRANDE XL": "GRANDE XL",
      "GRANDE CARGA XL": "GRANDE CON CARICATORE XL",
      "SKI": "SCI",
      "SKI CARGA": "SCI CON CARICATORE",
      "SKI XL": "SCI XL",
      "SKI CARGA XL": "SCI CON CARICATORE XL",
      "TAQUILLAS AGOTADAS": "ARMADIETTI ESAURITI",
      "Haz click aquí para volver a selección de zonas": "Clicca qui per tornare alla selezione della zona",
      //Accessibility View
      "¿NECESITAS UNA TAQUILLA ADAPTADA?": "HAI BISOGNO DI UN ARMADIETTO ADATTATO?",
      "Tienes la opción de seleccionar una taquilla de altura adaptada para tu confort.": "Hai la possibilità di selezionare un armadietto adattato in altezza per il tuo comfort.",
      "NO": "NO",
      "SI": "SÌ",
      //Payment View
      "CONFIRMA TU COMPRA": "CONFERMA IL TUO ACQUISTO",
      "Taquilla reservada durante": "Armadietto riservato per",
      "PARQUE": "PARCO",
      "ZONA": "ZONA",
      "ACCESIBLE": "ACCESSIBILE",
      "RESERVA": "PRENOTAZIONE",
      "TIPO DE TAQUILLA": "TIPO DI ARMADIETTO",
      "HASTA FINALIZAR EL DÍA": "FINO ALLA FINE DEL GIORNO",
      "IVA": "IVA",
      "IMPORTE BASE": "IMPORTO BASE",
      "IMPORTE IVA": "IMPORTO IVA",
      "TOTAL": "TOTALE",
      "Tiempo de reserva agotado": "Tempo di prenotazione scaduto",
      "Lo sentimos, pero no podemos reservar tu taquilla por más tiempo, otros usuarios deben poder acceder a ella. Vuelve a iniciar el proceso y finaliza la compra en el tiempo estipulado.": "Ci dispiace, ma non possiamo riservare il tuo armadietto per più tempo, altri utenti devono potervi accedere. Inizia di nuovo il processo e completa l'acquisto entro il tempo stabilito.",
      "Volver al inicio": "Torna all'inizio",
      "PAGAR": "PAGARE",
      "RESERVAR": "PRENOTARE",
      //Payment Error View
      "ERROR": "ERRORE",
      "No se ha podido realizar la compra. Vuelve a intentarlo más tarde.": "L'acquisto non ha potuto essere effettuato. Riprova più tardi.",
      "VOLVER": "TORNA A",
      "AL INICIO": "INIZIO",
      //Phone View
      "¡TU TAQUILLA HA SIDO RESERVADA!": "IL TUO ARMADIETTO È STATO PRENOTATO!",
      "FACILITANOS TUS DATOS DE RESPALDO": "FORNISCI I TUOI DATI DI BACKUP",
      "Tu teléfono se vinculará a la taquilla reservada para poder recuperar el Pin de esta en caso de que lo pierdas. Al email te llegará la confirmación de tu reserva.": "Il tuo telefono sarà collegato all'armadietto prenotato per poter recuperare il suo PIN nel caso lo perdessi. Riceverai la conferma della tua prenotazione via email.",
      "TELÉFONO*": "NUMERO DI TELEFONO*",
      "EMAIL*": "EMAIL*",
      "Acepto ceder mi correo electrónico para recibir la confirmación de mi reserva.": "Accetto di fornire la mia email per ricevere la conferma della mia prenotazione.",
      "Acepto suscribirme al boletín informativo de VACWAY para recibir contenido y notificaciones comerciales.": "Accetto di iscrivermi alla newsletter di VACWAY per ricevere contenuti e notifiche commerciali.",
      "CONTINUAR": "CONTINUA",
      "*Los datos personales recabados no se usarán en ningún caso para ofrecer comunicados comerciales de ningún tipo si el cliente no se suscribe a la newsletter.": "*I dati personali raccolti non saranno utilizzati in nessun caso per inviare comunicazioni commerciali di alcun tipo se il cliente non si iscrive alla newsletter.",
      //Ticket View
      "¡GRACIAS POR RESERVAR TU TAQUILLA!": "GRAZIE PER AVER PRENOTATO IL TUO ARMADIETTO!",
      "Sigue las instrucciones que encontrarás a continuación o en tu email.": "Segui le istruzioni che troverai di seguito o nella tua email.",
      "TICKET": "BIGLIETTO",
      "Nº TAQUILLA": "N° ARMADIETTO",
      "PIN": "PIN",
      "ZONA": "ZONA",
      "TIPO": "TIPO",
      "UBICACIÓN": "UBICAZIONE",
      "FACTURA": "FATTURA",
      "*Si deseas obtener el Pin en formato físico para llevarlo durante tu estancia en el parque contacta con los responsables de la zona de taquillas y enséñales tu email de reserva.": "*Se desideri ottenere il PIN in formato fisico per portarlo durante il tuo soggiorno nel parco, contatta i responsabili dell'area degli armadietti e mostra loro l'email di prenotazione.",
      "DESCARGAR TICKET": "SCARICA BIGLIETTO",
      "INSTRUCCIONES": "ISTRUZIONI",
      "PASO 1": "PASSO 1",
      "LOCALIZA TU TAQUILLA": "LOCALIZZA IL TUO ARMADIETTO",
      "PASO 2": "PASSO 2",
      "TOCA EL TECLADO": "TOCCA LA TASTIERA",
      "PASO 3": "PASSO 3",
      "INTRODUCE TU PIN": "INSERISCI IL TUO PIN",
      "PASO 4": "PASSO 4",
      "ABRE LAS VECES QUE QUIERAS": "APRI TANTE VOLTE QUANTE VUOI",
      //Ticket in Pdf (Ticket View)
      "Más información y garantía en www.vacway.com": "Ulteriori informazioni e garanzia su www.vacway.com",
      "Factura Simplificada": "Fattura Semplificata",
      "INFORMACIÓN DE TU LOCKER:": "INFORMAZIONI SUL TUO ARMADIETTO:",
      "Producto": "Prodotto",
      "UNIDADES": "Unità",
      "Fecha y Hora": "Data e Ora",
      //LockerSelectorBaqueiraView
      "2 EQUIPOS DE ESQUÍ": "2 SET DI SCI",
      "3 EQUIPOS DE ESQUÍ": "3 SET DI SCI",
      "4 EQUIPOS DE ESQUÍ": "4 SET DI SCI",
      "Selecciona...": "Seleziona...",
      "Taquillas": "Armadi",
      "Atrás": "Indietro",
      "Continuar": "Avanti",
      "Fecha de Inicio": "Data di Inizio",
      "Fecha de Fin": "Data di Fine",
      "LO SENTIMOS PERO ESTAS FECHAS NO ESTÁN DISPONIBLES, PRUEBA OTRAS U OTRO TIPO DE TAQUILLAS.": "CI DISPIACE, QUESTE DATE NON SONO DISPONIBILI. PROVA ALTRE DATE O UN ALTRO TIPO DI ARMADIO.",
      "ZONA": "ZONA",
      "TIPO": "TIPO",
      "CANTIDAD": "QUANTITÀ",
      "FECHAS": "DATE",
      "SELECCIONA EL TIPO DE TAQUILLA": "SELEZIONA IL TIPO DI ARMADIO",
      "SELECCIONA LA CANTIDAD": "SELEZIONA LA QUANTITÀ",
      "SELECCIONA LAS FECHAS": "SELEZIONA LE DATE",
      "REVISA Y CONFIRMA": "VERIFICA E CONFERMA",
      "El día actual no está disponible para selección después de las 18:00 hora local.": "Il giorno corrente non è disponibile per la selezione dopo le 18:00 ora locale.",
      "El alquiler online está limitado a 7 días consecutivos, para realizar reservas de larga duración diríjase al staff de Baqueira Store o solicítelo via email a skiservice@baqueira.es": "L'affitto online è limitato a 7 giorni consecutivi, per prenotazioni a lungo termine contattare lo staff del Baqueira Store o richiederlo via email a skiservice@baqueira.es.",
      //PhoneBaqueiraView
      "NOMBRE COMPLETO*": "NOME COMPLETO*",
      //TicketBaqueiraView
      "Disponible en tu email que se enviará el día de inicio de tu reserva": "Disponibile nella tua email, che verrà inviata il giorno di inizio della tua prenotazione",
      "FECHA COMPRA": "DATA DI ACQUISTO",
      //PaymentBaqueiraView
      "Te hemos aplicado un descuento en el precio, solo vas a pagar 5 días de tu reserva!": "Ti abbiamo applicato uno sconto sul prezzo; pagherai solo 5 giorni della tua prenotazione!",
      "IMPORTE ORIGINAL": "IMPORTO ORIGINALE"
    }
  },
  fr: {
    translation: {
      //Menu
      "Log in": "Se connecter",
      "Exit": "Sortie",
      //Step Bar
      "Zona": "Zone",
      "Taquilla": "Casier",
      "Pago": "Payer",
      "Email": "E-mail",
      //Home View
      "PARECE QUE ALGO HA FALLADO": "IT SEEMS SOMETHING HAS FAILED",
      "VUELVE A ESCANEAR EL QR": "SCAN THE QR AGAIN",
      //Zone Selector View
      "SELECCIONA LA ZONA DE TAQUILLAS": "SÉLECTIONNEZ LA ZONE DES CASIERS",
      "BIENVENIDO A": "BIENVENUE À",
      "CONSULTA EL MAPA DEL PARQUE": "CONSULTEZ LA CARTE DU PARC",
      "ZONA ": "ZONE ",
      //Locker Selector View
      "SELECCIONA EL TIPO DE TAQUILLA": "SÉLECTIONNEZ LE TYPE DE CASIER",
      "PARQUE": "PARC",
      "ZONA SELECCIONADA": "ZONE SÉLECTIONNÉE",
      "Taquilla": "Casier",
      "BÁSICA": "STANDARD",
      "BÁSICA CARGA": "STANDARD CHARGEUR",
      "BÁSICA XL": "STANDARD XL",
      "BÁSICA CARGA XL": "STANDARD CHARGEUR XL",
      "GRANDE": "GRAND",
      "GRANDE CARGA": "GRAND CHARGEUR",
      "GRANDE XL": "GRAND XL",
      "GRANDE CARGA XL": "GRAND CHARGEUR XL",
      "SKI": "SKI",
      "SKI CARGA": "SKI CHARGEUR",
      "SKI XL": "SKI XL",
      "SKI CARGA XL": "SKI CHARGEUR XL",
      "TAQUILLAS AGOTADAS": "CASIER ÉPUISÉ",
      "Haz click aquí para volver a selección de zonas": "Cliquez ici pour revenir à la sélection de la zone",
      //Accessibility View
      "¿NECESITAS UNA TAQUILLA ADAPTADA?": "AS-TU BESOIN D'UN CASIER ADAPTÉ ?",
      "Tienes la opción de seleccionar una taquilla de altura adaptada para tu confort.": "Vous avez la possibilité de sélectionner un casier adapté en hauteur pour votre confort.",
      "NO": "NON",
      "SI": "OUI",
      //Payment View
      "CONFIRMA TU COMPRA": "CONFIRMEZ VOTRE ACHAT",
      "Taquilla reservada durante": "Casier réservé pendant",
      "PARQUE": "PARC",
      "ZONA": "ZONE",
      "ACCESIBLE": "ACCESSIBLE",
      "RESERVA": "RÉSERVATION",
      "TIPO DE TAQUILLA": "TYPE DE CASIER",
      "HASTA FINALIZAR EL DÍA": "JUSQU'À LA FIN DE LA JOURNÉE",
      "IVA": "TVA",
      "IMPORTE BASE": "MONTANT DE BASE",
      "IMPORTE IVA": "MONTANT DE TVA",
      "TOTAL": "TOTAL",
      "Tiempo de reserva agotado": "Temps de réservation écoulé",
      "Lo sentimos, pero no podemos reservar tu taquilla por más tiempo, otros usuarios deben poder acceder a ella. Vuelve a iniciar el proceso y finaliza la compra en el tiempo estipulado.": "Nous sommes désolés, mais nous ne pouvons pas réserver votre casier plus longtemps, d'autres utilisateurs doivent pouvoir y accéder. Revenez au début du processus et terminez l'achat dans le délai imparti.",
      "Volver al inicio": "Retour au début",
      "PAGAR": "PAYER",
      "RESERVAR": "RÉSERVER",
      //Payment Error View
      "ERROR": "ERREUR",
      "No se ha podido realizar la compra. Vuelve a intentarlo más tarde.": "L'achat n'a pas pu être effectué. Réessayez plus tard.",
      "VOLVER": "RETOUR",
      "AL INICIO": "AU DÉBUT",
      //Phone View
      "¡TU TAQUILLA HA SIDO RESERVADA!": "VOTRE CASIER A ÉTÉ RÉSERVÉ !",
      "FACILITANOS TUS DATOS DE RESPALDO": "FOURNISSEZ-NOUS VOS DONNÉES DE SAUVEGARDE",
      "Tu teléfono se vinculará a la taquilla reservada para poder recuperar el Pin de esta en caso de que lo pierdas. Al email te llegará la confirmación de tu reserva.": "Votre téléphone sera lié au casier réservé pour pouvoir récupérer son code PIN en cas de perte. Vous recevrez la confirmation de votre réservation par e-mail.",
      "TELÉFONO*": "NUMÉRO DE TÉLÉPHONE*",
      "EMAIL*": "E-MAIL*",
      "Acepto ceder mi correo electrónico para recibir la confirmación de mi reserva.": "J'accepte de fournir mon e-mail pour recevoir la confirmation de ma réservation.",
      "Acepto suscribirme al boletín informativo de VACWAY para recibir contenido y notificaciones comerciales.": "J'accepte de m'abonner à la newsletter de VACWAY pour recevoir du contenu et des notifications commerciales.",
      "CONTINUAR": "CONTINUER",
      "*Los datos personales recabados no se usarán en ningún caso para ofrecer comunicados comerciales de ningún tipo si el cliente no se suscribe a la newsletter.": "*Les données personnelles collectées ne seront en aucun cas utilisées pour offrir des communications commerciales d'aucune sorte si le client ne s'abonne pas à la newsletter.",
      //Ticket View
      "¡GRACIAS POR RESERVAR TU TAQUILLA!": "MERCI D'AVOIR RÉSERVÉ VOTRE CASIER !",
      "Sigue las instrucciones que encontrarás a continuación o en tu email.": "Suivez les instructions que vous trouverez ci-dessous ou dans votre e-mail.",
      "TICKET": "BILLET",
      "Nº TAQUILLA": "N° DE CASIER",
      "PIN": "PIN",
      "ZONA": "ZONE",
      "TIPO": "TYPE",
      "UBICACIÓN": "EMPLACEMENT",
      "FACTURA": "FACTURE",
      "*Si deseas obtener el Pin en formato físico para llevarlo durante tu estancia en el parque contacta con los responsables de la zona de taquillas y enséñales tu email de reserva.": "*Si vous souhaitez obtenir le code PIN sous forme physique pour le porter pendant votre séjour dans le parc, contactez les responsables de la zone des casiers et montrez-leur votre e-mail de réservation.",
      "DESCARGAR TICKET": "TÉLÉCHARGER LE BILLET",
      "INSTRUCCIONES": "INSTRUCTIONS",
      "PASO 1": "ÉTAPE 1",
      "LOCALIZA TU TAQUILLA": "LOCALISEZ VOTRE CASIER",
      "PASO 2": "ÉTAPE 2",
      "TOCA EL TECLADO": "TOUCHEZ LE CLAVIER",
      "PASO 3": "ÉTAPE 3",
      "INTRODUCE TU PIN": "SAISISSEZ VOTRE CODE PIN",
      "PASO 4": "ÉTAPE 4",
      "ABRE LAS VECES QUE QUIERAS": "OUVREZ AUTANT DE FOIS QUE VOUS VOULEZ",
      //Ticket in Pdf (Ticket View)
      "Más información y garantía en www.vacway.com": "Plus d'informations et garantie sur www.vacway.com",
      "Factura Simplificada": "Facture Simplifiée",
      "INFORMACIÓN DE TU LOCKER:": "INFORMATIONS SUR VOTRE CASIER :",
      "Producto": "Produit",
      "UNIDADES": "Unités",
      "Fecha y Hora": "Date et Heure",
      //LockerSelectorBaqueiraView
      "2 EQUIPOS DE ESQUÍ": "2 ENSEMBLES DE SKIS",
      "3 EQUIPOS DE ESQUÍ": "3 ENSEMBLES DE SKIS",
      "4 EQUIPOS DE ESQUÍ": "4 ENSEMBLES DE SKIS",
      "Selecciona...": "Sélectionnez...",
      "Taquillas": "Casiers",
      "Atrás": "Retour",
      "Continuar": "Suivant",
      "Fecha de Inicio": "Date de Début",
      "Fecha de Fin": "Date de Fin",
      "LO SENTIMOS PERO ESTAS FECHAS NO ESTÁN DISPONIBLES, PRUEBA OTRAS U OTRO TIPO DE TAQUILLAS.": "DÉSOLÉ, CES DATES NE SONT PAS DISPONIBLES. ESSAYEZ D'AUTRES DATES OU UN AUTRE TYPE DE CASIER.",
      "ZONA": "ZONE",
      "TIPO": "TYPE",
      "CANTIDAD": "QUANTITÉ",
      "FECHAS": "DATES",
      "SELECCIONA EL TIPO DE TAQUILLA": "SÉLECTIONNEZ LE TYPE DE CASIER",
      "SELECCIONA LA CANTIDAD": "SÉLECTIONNEZ LA QUANTITÉ",
      "SELECCIONA LAS FECHAS": "SÉLECTIONNEZ LES DATES",
      "REVISA Y CONFIRMA": "VÉRIFIEZ ET CONFIRMEZ",
      "El día actual no está disponible para selección después de las 18:00 hora local.": "Le jour actuel n'est pas disponible à la sélection après 18h00 heure locale.",
      "El alquiler online está limitado a 7 días consecutivos, para realizar reservas de larga duración diríjase al staff de Baqueira Store o solicítelo via email a skiservice@baqueira.es": "La location en ligne est limitée à 7 jours consécutifs, pour les réservations de longue durée, contactez le personnel du Baqueira Store ou faites-en la demande par e-mail à skiservice@baqueira.es.",
      //PhoneBaqueiraView
      "NOMBRE COMPLETO*": "NOM COMPLET*",
      //TicketBaqueiraView
      "Disponible en tu email que se enviará el día de inicio de tu reserva": "Disponible dans votre email, qui sera envoyé le jour du début de votre réservation",
      "FECHA COMPRA": "DATE D'ACHAT",
      //PaymentBaqueiraView
      "Te hemos aplicado un descuento en el precio, solo vas a pagar 5 días de tu reserva!": "Nous avons appliqué une réduction sur le prix; vous ne paierez que pour 5 jours de votre réservation!",
      "IMPORTE ORIGINAL": "MONTANT ORIGINAL"
    }
  },
  pt: {
    translation: {
      //Menu
      "Log in": "Entrar",
      "Exit": "Sair",
      //Step Bar
      "Zona": "Zona",
      "Taquilla": "Armário",
      "Pago": "Pagamento",
      "Email": "E-mail",
      //Home View
      "PARECE QUE ALGO HA FALLADO": "IT SEEMS SOMETHING HAS FAILED",
      "VUELVE A ESCANEAR EL QR": "SCAN THE QR AGAIN",
      //Zone Selector View
      "SELECCIONA LA ZONA DE TAQUILLAS": "SELECIONE A ZONA DOS ARMÁRIOS",
      "BIENVENIDO A": "BEM-VINDO AO",
      "CONSULTA EL MAPA DEL PARQUE": "CONSULTE O MAPA DO PARQUE",
      "ZONA ": "ZONA ",
      //Locker Selector View
      "SELECCIONA EL TIPO DE TAQUILLA": "SELECIONE O TIPO DE ARMÁRIO",
      "PARQUE": "PARQUE",
      "ZONA SELECCIONADA": "ZONA SELECIONADA",
      "Taquilla": "Armário",
      "BÁSICA": "BÁSICA",
      "BÁSICA CARGA": "BÁSICA COM CARREGADOR",
      "BÁSICA XL": "BÁSICA XL",
      "BÁSICA CARGA XL": "BÁSICA COM CARREGADOR XL",
      "GRANDE": "GRANDE",
      "GRANDE CARGA": "GRANDE COM CARREGADOR",
      "GRANDE XL": "GRANDE XL",
      "GRANDE CARGA XL": "GRANDE COM CARREGADOR XL",
      "SKI": "ESQUI",
      "SKI CARGA": "ESQUI COM CARREGADOR",
      "SKI XL": "ESQUI XL",
      "SKI CARGA XL": "ESQUI COM CARREGADOR XL",
      "TAQUILLAS AGOTADAS": "ARMÁRIOS ESGOTADOS",
      "Haz click aquí para volver a selección de zonas": "Clique aqui para voltar à seleção de zona",
      //Accessibility View
      "¿NECESITAS UNA TAQUILLA ADAPTADA?": "PRECISA DE UM ARMÁRIO ADAPTADO?",
      "Tienes la opción de seleccionar una taquilla de altura adaptada para tu confort.": "Você tem a opção de selecionar um armário adaptado em altura para o seu conforto.",
      "NO": "NÃO",
      "SI": "SIM",
      //Payment View
      "CONFIRMA TU COMPRA": "CONFIRME A SUA COMPRA",
      "Taquilla reservada durante": "Armário reservado durante",
      "PARQUE": "PARQUE",
      "ZONA": "ZONA",
      "ACCESIBLE": "ACESSÍVEL",
      "RESERVA": "RESERVA",
      "TIPO DE TAQUILLA": "TIPO DE ARMÁRIO",
      "HASTA FINALIZAR EL DÍA": "ATÉ O FINAL DO DIA",
      "IVA": "IVA",
      "IMPORTE BASE": "VALOR BASE",
      "IMPORTE IVA": "VALOR DO IVA",
      "TOTAL": "TOTAL",
      "Tiempo de reserva agotado": "Tempo de reserva esgotado",
      "Lo sentimos, pero no podemos reservar tu taquilla por más tiempo, otros usuarios deben poder acceder a ella. Vuelve a iniciar el proceso y finaliza la compra en el tiempo estipulado.": "Lamentamos, mas não podemos reservar seu armário por mais tempo; outros usuários devem ter acesso a ele. Inicie o processo novamente e conclua a compra dentro do tempo estipulado.",
      "Volver al inicio": "Voltar ao início",
      "PAGAR": "PAGAR",
      "RESERVAR": "RESERVAR",
      //Payment Error View
      "ERROR": "ERRO",
      "No se ha podido realizar la compra. Vuelve a intentarlo más tarde.": "Não foi possível efetuar a compra. Tente novamente mais tarde.",
      "VOLVER": "VOLTAR",
      "AL INICIO": "INÍCIO",
      //Phone View
      "¡TU TAQUILLA HA SIDO RESERVADA!": "SEU ARMÁRIO FOI RESERVADO!",
      "FACILITANOS TUS DATOS DE RESPALDO": "FORNEÇA SEUS DADOS DE BACKUP",
      "Tu teléfono se vinculará a la taquilla reservada para poder recuperar el Pin de esta en caso de que lo pierdas. Al email te llegará la confirmación de tu reserva.": "Seu telefone será vinculado ao armário reservado para poder recuperar seu PIN caso você o perca. A confirmação da sua reserva será enviada por e-mail.",
      "TELÉFONO*": "NÚMERO DE TELEFONE*",
      "EMAIL*": "E-MAIL*",
      "Acepto ceder mi correo electrónico para recibir la confirmación de mi reserva.": "Aceito fornecer meu e-mail para receber a confirmação da minha reserva.",
      "Acepto suscribirme al boletín informativo de VACWAY para recibir contenido y notificaciones comerciales.": "Aceito assinar a newsletter da VACWAY para receber conteúdo e notificações comerciais.",
      "CONTINUAR": "CONTINUAR",
      "*Los datos personales recabados no se usarán en ningún caso para ofrecer comunicados comerciales de ningún tipo si el cliente no se suscribe a la newsletter.": "*Os dados pessoais coletados não serão usados, em nenhum caso, para oferecer comunicações comerciais de nenhum tipo, caso o cliente não assine a newsletter.",
      //Ticket View
      "¡GRACIAS POR RESERVAR TU TAQUILLA!": "OBRIGADO POR RESERVAR SEU ARMÁRIO!",
      "Sigue las instrucciones que encontrarás a continuación o en tu email.": "Siga as instruções que você encontrará abaixo ou no seu e-mail.",
      "TICKET": "BILHETE",
      "Nº TAQUILLA": "Nº DE ARMÁRIO",
      "PIN": "PIN",
      "ZONA": "ZONA",
      "TIPO": "TIPO",
      "UBICACIÓN": "LOCALIZAÇÃO",
      "FACTURA": "FATURA",
      "*Si deseas obtener el Pin en formato físico para llevarlo durante tu estancia en el parque contacta con los responsables de la zona de taquillas y enséñales tu email de reserva.": "*Se você deseja obter o PIN em formato físico para levar durante sua estadia no parque, entre em contato com os responsáveis da área de armários e mostre-lhes seu e-mail de reserva.",
      "DESCARGAR TICKET": "BAIXAR BILHETE",
      "INSTRUCCIONES": "INSTRUÇÕES",
      "PASO 1": "PASSO 1",
      "LOCALIZA TU TAQUILLA": "LOCALIZE SEU ARMÁRIO",
      "PASO 2": "PASSO 2",
      "TOCA EL TECLADO": "TOQUE NO TECLADO",
      "PASO 3": "PASSO 3",
      "INTRODUCE TU PIN": "INSIRA SEU PIN",
      "PASO 4": "PASSO 4",
      "ABRE LAS VECES QUE QUIERAS": "ABRA QUANTAS VEZES QUISER",
      //Ticket in Pdf (Ticket View)
      "Más información y garantía en www.vacway.com": "Mais informações e garantia em www.vacway.com",
      "Factura Simplificada": "Fatura Simplificada",
      "INFORMACIÓN DE TU LOCKER:": "INFORMAÇÕES DO SEU ARMÁRIO:",
      "Producto": "Produto",
      "UNIDADES": "Unidades",
      "Fecha y Hora": "Data e Hora",
      //LockerSelectorBaqueiraView
      "2 EQUIPOS DE ESQUÍ": "2 CONJUNTOS DE ESQUIS",
      "3 EQUIPOS DE ESQUÍ": "3 CONJUNTOS DE ESQUIS",
      "4 EQUIPOS DE ESQUÍ": "4 CONJUNTOS DE ESQUIS",
      "Selecciona...": "Selecione...",
      "Taquillas": "Armários",
      "Atrás": "Voltar",
      "Continuar": "Avançar",
      "Fecha de Inicio": "Data de Início",
      "Fecha de Fin": "Data de Fim",
      "LO SENTIMOS PERO ESTAS FECHAS NO ESTÁN DISPONIBLES, PRUEBA OTRAS U OTRO TIPO DE TAQUILLAS.": "DESCULPE, ESTAS DATAS NÃO ESTÃO DISPONÍVEIS. TENTE OUTRAS DATAS OU OUTRO TIPO DE ARMÁRIO.",
      "ZONA": "ZONA",
      "TIPO": "TIPO",
      "CANTIDAD": "QUANTIDADE",
      "FECHAS": "DATAS",
      "SELECCIONA EL TIPO DE TAQUILLA": "SELECIONE O TIPO DE ARMÁRIO",
      "SELECCIONA LA CANTIDAD": "SELECIONE A QUANTIDADE",
      "SELECCIONA LAS FECHAS": "SELECIONE AS DATAS",
      "REVISA Y CONFIRMA": "VERIFIQUE E CONFIRME",
      "El día actual no está disponible para selección después de las 18:00 hora local.": "O dia atual não está disponível para seleção após as 18h00, hora local.",
      "El alquiler online está limitado a 7 días consecutivos, para realizar reservas de larga duración diríjase al staff de Baqueira Store o solicítelo via email a skiservice@baqueira.es": "O aluguel online está limitado a 7 dias consecutivos. Para reservas de longa duração, entre em contato com a equipe da Baqueira Store ou solicite via e-mail para skiservice@baqueira.es.",
      //PhoneBaqueiraView
      "NOMBRE COMPLETO*": "NOME COMPLETO*",
      //TicketBaqueiraView
      "Disponible en tu email que se enviará el día de inicio de tu reserva": "Disponível no seu email, que será enviado no dia de início da sua reserva",
      "FECHA COMPRA": "DATA DE COMPRA",
      //PaymentBaqueiraView
      "Te hemos aplicado un descuento en el precio, solo vas a pagar 5 días de tu reserva!": "Aplicamos um desconto no preço; você pagará apenas 5 dias da sua reserva!",
      "IMPORTE ORIGINAL": "VALOR ORIGINAL"
    }
  },
  de: {
    translation: {
      //Menu
      "Log in": "Anmelden",
      "Exit": "Ausgang",
      //Step Bar
      "Zona": "Zone",
      "Taquilla": "Schließfach",
      "Pago": "Bezahlung",
      "Email": "E-Mail",
      //Home View
      "PARECE QUE ALGO HA FALLADO": "IT SEEMS SOMETHING HAS FAILED",
      "VUELVE A ESCANEAR EL QR": "SCAN THE QR AGAIN",
      //Zone Selector View
      "SELECCIONA LA ZONA DE TAQUILLAS": "WÄHLE DIE SCHLIESSFACHZONE AUS",
      "BIENVENIDO A": "WILLKOMMEN BEI",
      "CONSULTA EL MAPA DEL PARQUE": "SIEHE DEN PARKPLAN",
      "ZONA ": "ZONE ",
      //Locker Selector View
      "SELECCIONA EL TIPO DE TAQUILLA": "WÄHLE DEN SCHLIESSFACHTYP AUS",
      "PARQUE": "PARK",
      "ZONA SELECCIONADA": "AUSGEWÄHLTE ZONE",
      "Taquilla": "Schließfach",
      "BÁSICA": "STANDARD",
      "BÁSICA CARGA": "STANDARD MIT LADEGERÄT",
      "BÁSICA XL": "STANDARD XL",
      "BÁSICA CARGA XL": "STANDARD MIT LADEGERÄT XL",
      "GRANDE": "GROSS",
      "GRANDE CARGA": "GROSS MIT LADEGERÄT",
      "GRANDE XL": "GROSS XL",
      "GRANDE CARGA XL": "GROSS MIT LADEGERÄT XL",
      "SKI": "SKI",
      "SKI CARGA": "SKI MIT LADEGERÄT",
      "SKI XL": "SKI XL",
      "SKI CARGA XL": "SKI MIT LADEGERÄT XL",
      "TAQUILLAS AGOTADAS": "AUSVERKAUFTE SCHLIESSFÄCHER",
      "Haz click aquí para volver a selección de zonas": "Klicken Sie hier, um zur Zonenauswahl zurückzukehren",
      //Accessibility View
      "¿NECESITAS UNA TAQUILLA ADAPTADA?": "BRAUCHEN SIE EIN ANGEPASSTES SCHLIESSFACH?",
      "Tienes la opción de seleccionar una taquilla de altura adaptada para tu confort.": "Sie haben die Möglichkeit, ein höhenangepasstes Schließfach für Ihren Komfort auszuwählen.",
      "NO": "NEIN",
      "SI": "JA",
      //Payment View
      "CONFIRMA TU COMPRA": "BESTÄTIGEN SIE IHREN KAUF",
      "Taquilla reservada durante": "Schließfach reserviert für",
      "PARQUE": "PARK",
      "ZONA": "ZONE",
      "ACCESIBLE": "ZUGÄNGLICH",
      "RESERVA": "RESERVIERUNG",
      "TIPO DE TAQUILLA": "SCHLIESSFACHTYP",
      "HASTA FINALIZAR EL DÍA": "BIS ZUM ENDE DES TAGES",
      "IVA": "MWST",
      "IMPORTE BASE": "GRUNDBETRAG",
      "IMPORTE IVA": "MWST-BETRAG",
      "TOTAL": "GESAMT",
      "Tiempo de reserva agotado": "Reservierungszeit abgelaufen",
      "Lo sentimos, pero no podemos reservar tu taquilla por más tiempo, otros usuarios deben poder acceder a ella. Vuelve a iniciar el proceso y finaliza la compra en el tiempo estipulado.": "Es tut uns leid, aber wir können Ihr Schließfach nicht länger reservieren, andere Benutzer müssen darauf zugreifen können. Starten Sie den Prozess erneut und schließen Sie den Kauf innerhalb der vorgegebenen Zeit ab.",
      "Volver al inicio": "Zurück zum Anfang",
      "PAGAR": "BEZAHLEN",
      "RESERVAR": "RESERVIEREN",
      //Payment Error View
      "ERROR": "FEHLER",
      "No se ha podido realizar la compra. Vuelve a intentarlo más tarde.": "Der Kauf konnte nicht durchgeführt werden. Versuchen Sie es später erneut.",
      "VOLVER": "ZURÜCK",
      "AL INICIO": "ZUM ANFANG",
      //Phone View
      "¡TU TAQUILLA HA SIDO RESERVADA!": "DEIN SCHLIESSFACH WURDE RESERVIERT!",
      "FACILITANOS TUS DATOS DE RESPALDO": "GIB UNS DEINE BACKUP-DATEN",
      "Tu teléfono se vinculará a la taquilla reservada para poder recuperar el Pin de esta en caso de que lo pierdas. Al email te llegará la confirmación de tu reserva.": "Dein Telefon wird mit dem reservierten Schließfach verknüpft, um die PIN bei Verlust wiederherstellen zu können. Die Bestätigung deiner Reservierung wird per E-Mail gesendet.",
      "TELÉFONO*": "TELEFONNUMMER*",
      "EMAIL*": "E-MAIL*",
      "Acepto ceder mi correo electrónico para recibir la confirmación de mi reserva.": "Ich stimme zu, meine E-Mail weiterzugeben, um die Bestätigung meiner Reservierung zu erhalten.",
      "Acepto suscribirme al boletín informativo de VACWAY para recibir contenido y notificaciones comerciales.": "Ich stimme zu, den Newsletter von VACWAY zu abonnieren, um kommerzielle Inhalte und Benachrichtigungen zu erhalten.",
      "CONTINUAR": "WEITER",
      "*Los datos personales recabados no se usarán en ningún caso para ofrecer comunicados comerciales de ningún tipo si el cliente no se suscribe a la newsletter.": "*Die gesammelten persönlichen Daten werden in keinem Fall verwendet, um kommerzielle Mitteilungen jeglicher Art anzubieten, wenn der Kunde sich nicht für den Newsletter anmeldet.",
      //Ticket View
      "¡GRACIAS POR RESERVAR TU TAQUILLA!": "DANKE, DASS DU DEIN SCHLIESSFACH RESERVIERT HAST!",
      "Sigue las instrucciones que encontrarás a continuación o en tu email.": "Folge den Anweisungen unten oder in deiner E-Mail.",
      "TICKET": "TICKET",
      "Nº TAQUILLA": "SCHLIESSFACHNR.",
      "PIN": "PIN",
      "ZONA": "ZONE",
      "TIPO": "TYP",
      "UBICACIÓN": "STANDORT",
      "FACTURA": "RECHNUNG",
      "*Si deseas obtener el Pin en formato físico para llevarlo durante tu estancia en el parque contacta con los responsables de la zona de taquillas y enséñales tu email de reserva.": "*Wenn du die PIN physisch erhalten möchtest, um sie während deines Aufenthalts im Park mitzunehmen, kontaktiere die Verantwortlichen des Schließfachbereichs und zeige ihnen deine Reservierungs-E-Mail.",
      "DESCARGAR TICKET": "TICKET HERUNTERLADEN",
      "INSTRUCCIONES": "ANLEITUNGEN",
      "PASO 1": "SCHRITT 1",
      "LOCALIZA TU TAQUILLA": "FINDE DEIN SCHLIESSFACH",
      "PASO 2": "SCHRITT 2",
      "TOCA EL TECLADO": "BERÜHRE DIE TASTATUR",
      "PASO 3": "SCHRITT 3",
      "INTRODUCE TU PIN": "GIB DEINE PIN EIN",
      "PASO 4": "SCHRITT 4",
      "ABRE LAS VECES QUE QUIERAS": "ÖFFNE SO OFT DU WILLST",
      //Ticket in Pdf (Ticket View)
      "Más información y garantía en www.vacway.com": "Mehr Informationen und Garantie unter www.vacway.com",
      "Factura Simplificada": "Vereinfachte Rechnung",
      "INFORMACIÓN DE TU LOCKER:": "INFORMATIONEN ZU DEINEM SCHLIESSFACH:",
      "Producto": "Produkt",
      "UNIDADES": "Einheiten",
      "Fecha y Hora": "Datum und Uhrzeit",
      //LockerSelectorBaqueiraView
      "2 EQUIPOS DE ESQUÍ": "2 SKI-SETS",
      "3 EQUIPOS DE ESQUÍ": "3 SKI-SETS",
      "4 EQUIPOS DE ESQUÍ": "4 SKI-SETS",
      "Selecciona...": "Wählen...",
      "Taquillas": "Schließfächer",
      "Atrás": "Zurück",
      "Continuar": "Weiter",
      "Fecha de Inicio": "Startdatum",
      "Fecha de Fin": "Enddatum",
      "LO SENTIMOS PERO ESTAS FECHAS NO ESTÁN DISPONIBLES, PRUEBA OTRAS U OTRO TIPO DE TAQUILLAS.": "LEIDER SIND DIESE DATEN NICHT VERFÜGBAR. VERSUCHEN SIE ANDERE DATEN ODER EINE ANDERE ART VON SCHLIESSFACH.",
      "ZONA": "ZONE",
      "TIPO": "TYP",
      "CANTIDAD": "ANZAHL",
      "FECHAS": "DATEN",
      "SELECCIONA EL TIPO DE TAQUILLA": "WÄHLEN SIE DEN SCHLIESSFACH-TYP",
      "SELECCIONA LA CANTIDAD": "WÄHLEN SIE DIE ANZAHL",
      "SELECCIONA LAS FECHAS": "WÄHLEN SIE DIE DATEN",
      "REVISA Y CONFIRMA": "ÜBERPRÜFEN UND BESTÄTIGEN",
      "El día actual no está disponible para selección después de las 18:00 hora local.": "Der aktuelle Tag ist nach 18:00 Uhr Ortszeit nicht mehr zur Auswahl verfügbar.",
      "El alquiler online está limitado a 7 días consecutivos, para realizar reservas de larga duración diríjase al staff de Baqueira Store o solicítelo via email a skiservice@baqueira.es": "Die Online-Miete ist auf 7 aufeinanderfolgende Tage begrenzt. Für Langzeitreservierungen wenden Sie sich bitte an das Personal des Baqueira Store oder kontaktieren Sie uns per E-Mail unter skiservice@baqueira.es.",
      //PhoneBaqueiraView
      "NOMBRE COMPLETO*": "VOLLSTÄNDIGER NAME*",
      //TicketBaqueiraView
      "Disponible en tu email que se enviará el día de inicio de tu reserva": "Verfügbar in Ihrer E-Mail, die am Startdatum Ihrer Reservierung gesendet wird",
      "FECHA COMPRA": "KAUFDATUM",
      //PaymentBaqueiraView
      "Te hemos aplicado un descuento en el precio, solo vas a pagar 5 días de tu reserva!": "Wir haben einen Rabatt auf den Preis angewendet; Sie zahlen nur für 5 Tage Ihrer Reservierung!",
      "IMPORTE ORIGINAL": "ORIGINALBETRAG"
    }
  },
};

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "es",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
